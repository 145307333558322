

import { Page } from '@/types/common'
import { EvaluationProjectItem } from '@/types/evaluation'
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class EvaluationProject extends Vue {
  searchForm = {
    projectName: '',
    evaluationMonth: ''
  }

  pickerOptions = {
    disabledDate: (time: Date) => {
      const date = this.$dayjs().subtract(1, 'month').format('YYYY/MM')
      const current = this.$dayjs(date).valueOf()
      return time.getTime() > current
    }
  }

  page = 1
  size = 10
  total = 0
  items: EvaluationProjectItem[] = []

  created () {
    this.loadData()
  }

  loadData () {
    this.$axios.get<Page<EvaluationProjectItem>>(this.$apis.evaluation.selectEvaluationByList, {
      ...this.searchForm,
      page: this.page,
      size: this.size
    }).then(res => {
      this.items = res.list
      this.total = res.total
    })
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  deleteRow (evaluationId: string) {
    this.$confirm('确认删除该记录嘛？', '提示').then(() => {
      this.$axios.post(this.$apis.evaluation.deleteEvaluation, {
        evaluationId
      }).then(res => {
        this.loadData()
      })
    })
  }
}
